import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const data = [
  {
    id: "WorklistMigration",
    title:
      "RFC – Final UI Migration: Worklist Redesign and Move to ohif/ui-next",
    summary: () => (
      <p>
        With the viewer page now migrated to ohif/ui-next in version 3.10, the
        Worklist is the last major component still relying on the legacy UI.
        We're planning to migrate and redesign it with responsive layouts, better
        data handling, and support for thumbnails and series selection. This RFC
        is open for feedback.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          In OHIF Viewer 3.10, we completed the migration of the viewer page —
          including tools, viewports, and layout controls — to the new
          ohif/ui-next framework. The only major component still using the legacy
          OHIF/UI is the Worklist. As we aim to fully deprecate the legacy UI,
          we're opening this RFC to guide the final phase of the migration and an
          accompanying redesign.
        </p>
        <p className="font-bold">Planned Updates:</p>
        <ul className="list-disc pl-5">
          <li>
            Migration to ohif/ui-next: Align the Worklist with our new component
            system built on shadcn/ui and radix-ui.
          </li>
          <li>Redesigned Layout: Improve structure and navigation for better usability.</li>
          <li>
            Thumbnail Support: Optional study and series thumbnails for faster
            recognition.
          </li>
          <li>
            Series Selection: Allow users to select specific series to load into
            the viewer.
          </li>
          <li>
            Responsive UI: Tailor the design to work well on tablet and mobile
            devices.
          </li>
          <li>
            Revamped Data Source Selector: Make it easier to switch between PACS,
            local files, and other data sources.
          </li>
        </ul>
        <p className="font-bold">Request for Comment:</p>
        <p>
          We're looking for feedback from the community before finalizing the
          design:
        </p>
        <ul className="list-disc pl-5">
          <li>What parts of the current Worklist do you use most?</li>
          <li>What feels clunky or outdated?</li>
          <li>Would thumbnails or per-series loading improve your workflow?</li>
          <li>Any suggestions for the data source selector?</li>
        </ul>
        <p className="font-bold">
          📋 Help Us Shape the New Worklist
        </p>
        <p>
          <OutboundLink
            href="https://docs.google.com/forms/d/e/1FAIpQLSdDcTb_1WSaXUMA2_f8IZX_6U-0keQOcKuTwSWUQjc_iTePfw/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-100"
          >
            Fill out this short survey
            {' '}
          </OutboundLink>
          and share your feedback on the questions above. Your input will directly influence the design and priorities for
          the new Worklist and data source selector.
        </p>
      </div>
    )
  },
  {
    id: "RTDoseOverlay",
    title: "Add Support for RTDose Overlay in OHIF Viewer",
    summary: () => (
      <p>
        We will introduce support for Radiation Therapy (RT) Dose visualization
        within the OHIF Viewer. Users will be able to overlay RT Dose data
        directly onto imaging modalities (e.g., CT, MR), enabling improved
        assessment and planning in radiation therapy workflows.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          Version 3.11 of the OHIF Viewer will add comprehensive support for
          Radiation Therapy (RT) Dose objects in DICOM format. With this
          enhancement, users can overlay RT Dose distributions seamlessly onto
          medical images, such as CT and MR scans, directly within the viewport.
        </p>
        <p>
          This feature allows for accurate visualization of dose distributions,
          facilitating more precise planning, verification, and analysis in
          radiation therapy treatments. Users will have control over dose
          visualization settings, such as transparency, color mapping, and dose
          thresholds, enhancing usability and integration into clinical workflows.
        </p>
      </div>
    )
  },
  {
    id: "RTSSMultiPlanar", 
    title: "Advanced Multi-Planar Visualization of RT Structure Sets",
    summary: () => (
      <p>
        We will enhance OHIF Viewer with advanced multi-planar visualization for
        Radiation Therapy Structure Sets (RTSS). Users will be able to reconstruct
        and accurately visualize structure contours in arbitrary planes beyond
        the original acquisition plane, significantly improving precision and
        flexibility in radiation treatment planning.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          In version 3.11, OHIF Viewer will introduce sophisticated multi-planar
          reconstruction and visualization capabilities for RTSS. Until now, RT
          Structure Sets could only be viewed accurately within their original
          acquisition plane. This feature involves complex surface reconstruction
          algorithms to ensure accurate cutting and rendering of RT Structure
          Sets, providing clinicians and researchers with enhanced spatial
          understanding and improved accuracy for radiation therapy planning.
        </p>
      </div>
    )
  },
  {
    id: "EnhancedFusionControls",
    title: "Enhanced Data Fusion and Overlay Controls in Viewport Dialog",
    summary: () => (
      <p>
        We're expanding the viewport dialog to support advanced data fusion
        capabilities. Users will easily overlay and manage multiple datasets
        (e.g., PET on CT, RT Dose overlays) directly within each viewport, with
        intuitive controls for opacity, visibility, and blending.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          Version 3.11 will enhance the OHIF Viewer's viewport dialog, adding
          sophisticated capabilities for data fusion and advanced overlay
          management. Currently, the viewport dialog allows basic management of
          segmentations. With this update, the viewer will automatically detect
          compatible datasets within the same frame of reference (such as PET
          images over CT scans or RT Dose overlays) and allow users to seamlessly
          fuse these data sources within the viewport.
        </p>
        <p>Users will gain comprehensive control over data overlays, including:</p>
        <ul className="list-disc pl-5">
          <li>
            Automatic detection of available overlay datasets for quick
            selection.
          </li>
          <li>Adding, removing, and toggling visibility of overlays per viewport.</li>
          <li>
            Adjusting opacity and blending levels interactively, providing detailed
            control of how foreground and background datasets appear.
          </li>
        </ul>
        <p>
          This enhancement significantly improves flexibility in clinical
          workflows, especially for multimodality imaging and radiation therapy
          planning.
        </p>
      </div>
    )
  }
];

export default data;
